@font-face {
  font-display: swap;
  font-family: 'Elle Futura';
  font-style: normal;
  font-weight: normal;
  src: url('~assets/fonts/ElleFutura.woff2') format('woff2'),
    url('~assets/fonts/ElleFutura.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Elle Futura';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/ElleFutura-Medium.woff2') format('woff2'),
    url('~assets/fonts/ElleFutura-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Elle Futura';
  font-style: italic;
  font-weight: 300;
  src: url('~assets/fonts/ElleFutura-LightItalic.woff2') format('woff2'),
    url('~assets/fonts/ElleFutura-LightItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Elle Futura';
  font-style: normal;
  font-weight: bold;
  src: url('~assets/fonts/ElleFutura-ExtraBold.woff2') format('woff2'),
    url('~assets/fonts/ElleFutura-ExtraBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Elle Futura';
  font-style: normal;
  font-weight: bold;
  src: url('~assets/fonts/ElleFutura-Bold.woff2') format('woff2'),
    url('~assets/fonts/ElleFutura-Bold.woff') format('woff');
}
