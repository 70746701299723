@mixin iconImage($width: 24, $height: 24, $url: '') {
  background-image: url($url);
  height: rem($height);
  width: rem($width);
}

.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;

  &-loading {
    @include iconImage(24, 24, '~assets/icons/loading.svg');
  }
}
