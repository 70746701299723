.p-home {
  $root: &;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  background-image: url('~assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &-1 {
    .form {
      display: block !important;
    }
    .nnris_big {
      display: none;
    }
  }

  &-2 {
    .qr {
      display: block !important;
    }
  }

  .panel {
    padding: rem(18);
  }

  .logo {
    display: flex;
    justify-content: space-between;
  }

  .nutifood {
    width: rem(70);
  }

  .nnris {
    width: rem(72);
  }

  .nnris_big {
    width: rem(150);
    position: absolute;
    left: 0;
    bottom: 20%;
  }

  .form {
    display: none;

    h2 {
      @include font-base(20, 36);
      margin-top: rem(26);
      margin-bottom: 0;
      text-align: center;
      color: #00609C;
      text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.8);
      font-weight: bold;
    }


    .field {
      display: flex;
      flex-direction: column;

      label {
        text-align: center;
        margin-bottom: rem(8);
        margin-top: rem(12);
        @include font-base(14, 21);
        font-weight: 500;
        color: #00609C;
      }

      input {
        height: rem(40);
        padding: 0 rem(16);
        text-align: center;
        font-family: inherit;
        outline: 0;
        border: 1px solid #00609C;
        background-color: #F6FAFF;
        border-radius: rem(8);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        &::-webkit-contacts-auto-fill-button {
          margin: 0;
          width: 0;
          background-color: transparent;
        }
      }

      span {
        @include font-base(12, 16);
        font-family: inherit;
        text-align: center;
        margin-top: rem(4);
        color: red;
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      margin-top: rem(26);
      position: relative;

      button {
        background-color: transparent;
        padding: 0;
        border: none;
        width: rem(236);
        // display: flex;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: inherit;
        color: #00609C;
        font-weight: 700;
        @include font-base(20, 32);
      }

      .a-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .qr {
    display: none;
    margin-top: rem(63);

    h2 {
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      @include font-base(20, 32);
      &.highlight {
        color: #00609C;
        letter-spacing: -0.01em;
      }
    }

    .thumbnail {
      max-width: rem(144);
      margin: rem(24) auto rem(32);
      position: relative;
      .qr_main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(112);
        height: rem(112);
      }
    }
  }
}